import React, { useState } from "react";

import Container from "components/container";

import Sidebar from "./sidebar";
import Navbar from "./navbar";

import style from "./layout.module.scss";

const Layout = ({ children }) => {
  const [openSidebar, setOpenSidebar] = useState(false);

  return (
    <div className={style.layoutWrapper}>
      <aside className={openSidebar ? style.sideBarOpen : ""}>
        {openSidebar && (
          <div
            className={style.backdropDiv}
            onClick={() => setOpenSidebar(false)}
          ></div>
        )}
        <Sidebar openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} />
      </aside>

      <main>
        <Navbar setOpenSidebar={setOpenSidebar} />
        <Container className={style.containerStyle}>{children}</Container>
      </main>
    </div>
  );
};

export default Layout;
