import axios from "utils/axios";
import { store, setBrandList } from "store";
import { BRAND_NAME_ROUTE } from "utils/endpoints";

export const getBrandList = async ({ setIsPending }) => {
  setIsPending(true);
  const res = await axios.get(BRAND_NAME_ROUTE);
  if (res.status === 200) {
    store.dispatch(
      setBrandList({
        brandList: res?.data?.users?.records,
      })
    );
    setIsPending(false);
  }
};

export const AddBrandName = async ({
  data,
  reset,
  brandList,
  setIsLoading,
}) => {
  setIsLoading(true);
  const res = await axios.post(BRAND_NAME_ROUTE, data);
  if (res.status === 201) {
    store.dispatch(
      setBrandList({
        brandList: [...brandList, ...[res?.data]],
      })
    );
    setIsLoading(false);
    reset();
  }
};

export const UpdateBrandName = async ({
  data,
  reset,
  deleteId,
  brandList,
  setIsUpdate,
  setIsLoading,
}) => {
  setIsLoading(true);
  const res = await axios.put(`${BRAND_NAME_ROUTE}/${deleteId}`, data);
  if (res.status === 200) {
    reset({ name: "" });
    setIsUpdate(false);
    setIsLoading(false);
    store.dispatch(
      setBrandList({
        brandList: brandList.map((obj) =>
          obj.id === deleteId ? { ...obj, name: data.name } : obj
        ),
      })
    );
  }
};

export const deleteBrandName = async ({
  deleteId,
  brandList,
  setOpenDelete,
  setDeleteLoading,
}) => {
  setDeleteLoading(true);
  const res = await axios.delete(BRAND_NAME_ROUTE + "/" + deleteId);
  if (res.status === 204) {
    setDeleteLoading(false);
    setOpenDelete(false);
    store.dispatch(
      setBrandList({
        brandList: brandList.filter((ele) => ele.id !== deleteId),
      })
    );
  }
  if (res.status === 422) {
    console.error(" error", res?.status);
  }
  setDeleteLoading(false);
  setOpenDelete(false);
};
