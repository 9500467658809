import React from "react";
import { Carousel } from "react-responsive-carousel";

import Card from "components/card";
import Input from "components/input";
import Button from "components/button";
import Loading from "components/loading";
import Container from "components/container";
import Accordion from "components/accordion/Accordion";

import { useViewList } from "./helper";

import logo from "assets/logo.png";
import style from "./view-list.module.scss";
import { useAppSelector } from "store/hooks";


const SearchResult = () => {
  const { token, user } = useAppSelector((state) => state?.app);
  const {
    id,
    searchValue,
    isLoading,
    isLoadingFilter,
    productList,
    filterList,
    handleSearchResult,
    handleSearchByCode,
    getSearchInput,
  } = useViewList();



  return (
    <>
      <div className={style.mainWrapper}>
        <Container className={style.contentWrapper}>
          <div className={style.searchWrapper}>
            <Input
              placeholder={"Search By Product"}
              value={searchValue}
              onChange={getSearchInput}
              className={style.searchInput}
              inputClass={style.innerInput}
            />
            <Button
              title={"Search"}
              handleClick={handleSearchResult}
              className={style.btnSearch}
            />
          </div>
          <p className={style.totalCount}>
            Search Result:
            <label
              style={{
                textDecoration: "underline",
                cursor: "pointer",
                marginLeft: "5px",
              }}
            >
              {id}
            </label>
          </p>
          {searchValue && (
            <Card className={style.cardFilterList}>
              {!isLoadingFilter ? (
                filterList.length > 0 ? (
                  filterList?.map((ele, index) => (
                    <div
                      className={style.data}
                      onClick={() => handleSearchByCode(ele[0])}
                      key={index}
                    >
                      {ele[1]}
                    </div>
                  ))
                ) : (
                  <div className={style.data}>No Result Found 😔</div>
                )
              ) : (
                <Loading className={style.loader} />
              )}
            </Card>
          )}
          <div className={style.resultWrapper}>
            {!isLoading ? (
              productList?.map((ele, index) => (
                <Card className={style.cardList} key={index}>
                  <div className={style.imgWrapper}>
                    {ele?.product_image_urls.length > 0 ? (
                      <Carousel
                        showStatus={false}
                        showIndicators={true}
                        dynamicHeight={true}
                        swipeable={true}
                        emulateTouch={true}
                        showThumbs={false}
                      >
                        {ele?.product_image_urls?.map(({ url }, index) => (
                          <img
                            src={url || logo}
                            className={style.logo}
                            alt={"product pic"}
                            key={index}
                          />
                        ))}
                      </Carousel>
                    ) : (
                      <img
                        src={logo}
                        className={style.logo}
                        alt={"product image"}
                      />
                    )}
                  </div>
                  <div className={style.contentWrapper}>
                    <h4
                      className={style.title}
                      onClick={() => handleSearchByCode(ele?.id)}
                    >
                      {ele?.code}
                    </h4>
                    <div className={style.detailWrapper}>
                      <div className={style.row}>
                        <p>Code:</p>
                        <p>{ele?.code}</p>
                      </div>
                      <div className={style.row}>
                        <p>Brand:</p>
                        <p>{ele?.brand.name}</p>
                      </div>
                      <div className={style.row}>
                        <p>Type:</p>
                        <p>{ele.product_type}</p>
                      </div>
                      <div className={style.row}>
                        <p>Price:</p>
                        <p>${ele?.total_reduce_price}</p>
                      </div>
                      <div className={style.row}>
                        <p>Weight (Gram):</p>
                        <p>{ele?.total_weight}</p>
                      </div>
                      {token && user?.role?.toLowerCase() === "admin" && (
                        <>
                          <div className={style.row}>
                            <p>Actual Price:</p>
                            <p>${ele?.total_actual_price}</p>
                          </div>
                          <div className={style.row}>
                            <p>PT:</p>
                            <p>{ele?.total_pt}</p>
                          </div>
                          <div className={style.row}>
                            <p>PD:</p>
                            <p>{ele?.total_pd}</p>
                          </div>
                          <div className={style.row}>
                            <p>RH:</p>
                            <p>{ele.total_rh}</p>
                          </div>
                        </>
                      )}
                      {ele?.sub_products?.length > 0 && (
                        <div className={style.gridThree}>
                          {ele?.sub_products?.map((innerEle, innerIndex) => (
                            <div
                              className={style.accordionWrapper}
                              key={innerIndex}
                            >
                              <Accordion title={`Product - ${innerIndex + 1}`}>
                                <div className={style.row}>
                                  <p>Weight (Gram):</p>
                                  <p>{innerEle?.weight}</p>
                                </div>
                                <div className={style.row}>
                                  <p>Price:</p>
                                  <p>${innerEle?.reduce_price}</p>
                                </div>
                                {token &&
                                  user?.role?.toLowerCase() === "admin" && (
                                    <>
                                      <div className={style.row}>
                                        <p>Actual Price: </p>
                                        <p>${innerEle?.actual_price}</p>
                                      </div>
                                      <div className={style.row}>
                                        <p>PT:</p>
                                        <p>{innerEle?.pt}</p>
                                      </div>
                                      <div className={style.row}>
                                        <p>PD:</p>
                                        <p>{innerEle?.pd}</p>
                                      </div>
                                      <div className={style.row}>
                                        <p>RH:</p>
                                        <p>{innerEle?.rh}</p>
                                      </div>
                                    </>
                                  )}
                              </Accordion>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    {token && user?.role?.toLowerCase() === "admin" && (
                      <>
                        <div className={style.ButtonWrapper}>
                          <Button
                            title={"Show"}
                            handleClick={() => handleSearchByCode(ele?.id)}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </Card>
              ))
            ) : (
              <Card>
                <Loading className={style.loader} />
              </Card>
            )}
          </div>
        </Container>
      </div>
      <div className={style.footerWrapper}>
        <div className={style.companyInfo}>
          © 2022 Force Convertor – All Rights Reserved
        </div>
        <div className={style.addressInfo}>
          UAE-Dubai Land Area: Um Ramool Plot Number: 210-0, UAE: 00 971 56 591
          7157 | USA: +1 818 434-3431
        </div>
      </div>
    </>
  );
};

export default SearchResult;
