import { useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  updateProfilePassword,
  updateProfileUsername,
} from "api-services/profile-services";
import { useAppSelector } from "store/hooks";

export const useProfile = () => {
  const { token } = useAppSelector((state) => state?.app);
  const tabLinks = ["Edit Username", "Edit Profile Image", "Change Password"];

  const [isLoading, setIsLoading] = useState(false);
  const [active, setActive] = useState(0);

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      active === 2 ? passwordSchema : active === 0 && nameSchema
    ),
  });

  const updatePassword = (data) => {
    updateProfilePassword({ data, setIsLoading });
  };

  const updateUsername = (data) => {
    updateProfileUsername({ data, token, setIsLoading });
  };

  return {
    reset,
    register,
    setActive,
    handleSubmit,
    updatePassword,
    updateUsername,
    errors,
    active,
    tabLinks,
    isLoading,
  };
};

export const passwordSchema = yup.object().shape({
  old_password: yup.string().required("Please enter the old password"),
  password: yup.string().required("Please enter the password"),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Please enter the password"),
});

export const nameSchema = yup.object().shape({
  name: yup.string().required("Please enter the username"),
});
