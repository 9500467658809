import { useEffect, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useAppSelector } from "store/hooks";
import { getSetting, updateSetting } from "api-services/settings-services";

export const useSettings = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { setting } = useAppSelector((state) => state?.app);

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(settingSchema),
  });

  useEffect(() => {
    Object.keys(setting).length === 0 ? getSettingFormValues() : reset(setting)
  }, []);

  const getSettingFormValues = () => {
    getSetting({ reset });
  };

  const onSubmit = (data) => {
    updateSetting({ data, setIsLoading, reset });
  };

  return {
    register,
    onSubmit,
    handleSubmit,
    errors,
    isLoading,
  };
};

export const settingSchema = yup.object().shape({
  reduce_price: yup.string().required("Please enter the percentage"),
  actual_price: yup.string().required("Please enter the percentage"),
  pt_price: yup.string().required("Please enter the metal price of PT"),
  pd_price: yup.string().required("Please enter the metal price of PD"),
  rh_price: yup.string().required("Please enter the metal price of RH"),
});
