import axios from "utils/axios";
import { store, setSetting } from "store";
import { SETTINGS_ROUTE } from "utils/endpoints";

export const updateSetting = async ({ data, setIsLoading, reset }) => {
  setIsLoading(true);
  try {
    const res = await axios.put(SETTINGS_ROUTE, data);
    if (res.status === 200) {
      setIsLoading(false);
      reset(res?.data?.setting);
    }
  } catch {
    setIsLoading(false);
  }
};

export const getSetting = async ({ reset }) => {
  const res = await axios.get(SETTINGS_ROUTE);
  if (res.status === 200) {
    reset(res?.data?.setting)
    store.dispatch(
      setSetting({
        setting: res?.data?.setting,
      })
    );
  }
};
