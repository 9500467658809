import React from "react";
import DataTable, { defaultThemes } from "react-data-table-component";

import Input from "components/input";
import Button from "components/button";
import Container from "components/container";

import { useBrandName } from "./helper";

import style from "./brand.module.scss";
import Loading from "components/loading";
import DeleteConfirmModal from "components/confirm-delete-modal";

const BrandName = () => {
  const {
    onSubmit,
    register,
    setDeleteId,
    handleSubmit,
    handleDelete,
    setOpenDelete,
    errors,
    columns,
    isUpdate,
    brandList,
    isLoading,
    isPending,
    openDelete,
    DeleteLoading,
  } = useBrandName();

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  return (
    <>
      <Container className={style.mainWrapper}>
        <h2>Add Brand</h2>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Input
            label={"Brand Name"}
            name={"name"}
            register={register}
            type={"text"}
            errorMessage={errors?.name?.message}
          />
          <Button
            title={isUpdate ? "Update" : "Add"}
            isLoading={isLoading}
            className={style.btnUpdate}
            type={"submit"}
          />
        </form>
        <div className={style.tableWrapper}>
          <DataTable
            highlightOnHover
            pointerOnHover
            columns={columns}
            data={brandList}
            progressPending={isPending}
            pagination
            customStyles={customStyles}
            fixedHeader={true}
            paginationRowsPerPageOptions={[7, 15, 20, 100]}
            progressComponent={<Loading className={style.loadingTable} />}
            fixedHeaderScrollHeight={"450px"}
          />
        </div>
      </Container>
      <div className={style.footerWrapper}>
        <div className={style.companyInfo}>
          © 2022 Force Convertor – All Rights Reserved
        </div>
        <div className={style.addressInfo}>
          UAE-Dubai Land Area: Um Ramool Plot Number: 210-0, UAE: 00 971 56 591
          7157 | USA: +1 818 434-3431
        </div>
      </div>
      {openDelete && (
        <DeleteConfirmModal
          deleteModalOpen={openDelete}
          setDeleteModalOpen={setOpenDelete}
          setDeleteId={setDeleteId}
          deleteLoading={DeleteLoading}
          handleDeleteRow={handleDelete}
        />
      )}
    </>
  );
};

export default BrandName;
