export const LOGIN_ROUTE = "/authentication/authenticate";
export const LOGIN_INFO_ROUTE = "/authentication/info";

export const BRAND_NAME_ROUTE = "/brands";

export const Add_PRODUCT_ROUTE = "/products";
export const SEARCH_PRODUCT_ROUTE = "/products/search";
export const SEARCH_WITH_PRODUCT_ROUTE = "/products/search_with_data";

export const PROFILE_ROUTE = "/authentication/update_profile";
export const UPDATE_PASSWORD_ROUTE = "/authentication/update_password";

export const SETTINGS_ROUTE = "/setting";

export const ATTACHMENT_ROUTE = "/attachments/";
