import React from "react";

import Card from "components/card";
import Input from "components/input";
import Button from "components/button";
import Container from "components/container";

import { useProfile } from "./helper";

import avatar from "assets/avatar.svg";
import style from "./profile.module.scss";

const Profile = () => {
  const {
    register,
    setActive,
    handleSubmit,
    updatePassword,
    updateUsername,
    errors,
    active,
    tabLinks,
    isLoading,
  } = useProfile();

  return (
    <>
      <Container className={style.mainWrapper}>
        <h2>Profile</h2>
        <Card>
          <div className={style.imgWrapper}>
            <img src={avatar} alt="profile-img" className={style.profileImg} />
          </div>
          <div className={style.navTab}>
            <ul>
              {tabLinks?.map((ele, index) => (
                <li
                  key={index}
                  className={active === index ? style.active : ""}
                  onClick={() => setActive(index)}
                >
                  {ele}
                </li>
              ))}
            </ul>
          </div>
          <div className={style.contentWrapper}>
            {active === 0 && (
              <UpdateUserName
                errors={errors}
                register={register}
                isLoading={isLoading}
                handleSubmit={handleSubmit}
                updateUsername={updateUsername}
              />
            )}
            {active === 1 && <ProfileImg />}
            {active === 2 && (
              <ChangePassword
                errors={errors}
                register={register}
                isLoading={isLoading}
                handleSubmit={handleSubmit}
                updatePassword={updatePassword}
              />
            )}
          </div>
        </Card>
      </Container>
      <div className={style.footerWrapper}>
        <div className={style.companyInfo}>
          © 2022 Force Convertor – All Rights Reserved
        </div>
        <div className={style.addressInfo}>
          UAE-Dubai Land Area: Um Ramool Plot Number: 210-0, UAE: 00 971 56 591
          7157 | USA: +1 818 434-3431
        </div>
      </div>
    </>
  );
};

export default Profile;

export const UpdateUserName = ({
  updateUsername,
  handleSubmit,
  isLoading,
  register,
  errors,
}) => {
  return (
    <>
      <form
        style={{ padding: "10px 0px" }}
        onSubmit={handleSubmit(updateUsername)}
      >
        <Input
          name={"name"}
          type={"text"}
          label={"username*"}
          register={register}
          errorMessage={errors?.name?.message}
        />
        <div className={style.btnWrapper}>
          <Button
            type={"submit"}
            isLoading={isLoading}
            title={"Update Username"}
            className={style.btnUpdate}
          />
        </div>
      </form>
    </>
  );
};

export const ProfileImg = () => {
  return (
    <>
      <form style={{ padding: "10px 0px" }} onSubmit>
        <div className={style.imgDivWrapper}>{/* <img src={logo} /> */}</div>
        <div className={style.btnWrapper}>
          <Button
            title={"Update Profile Image"}
            type={"button"}
            className={style.btnUpdate}
          />
          <Button
            title={"Remove Profile Image"}
            type={"button"}
            className={style.btnRemove}
          />
        </div>
      </form>
    </>
  );
};

export const ChangePassword = ({
  updatePassword,
  handleSubmit,
  register,
  errors,
  isLoading,
}) => {
  return (
    <>
      <form
        style={{ padding: "10px 0px" }}
        onSubmit={handleSubmit(updatePassword)}
        autoComplete={"off"}
      >
        <div style={{ marginBottom: "15px" }}>
          <Input
            label={"Old Password*"}
            name={"old_password"}
            register={register}
            type={"text"}
            errorMessage={errors?.old_password?.message}
          />
        </div>
        <div style={{ marginBottom: "15px" }}>
          <Input
            label={"New Password*"}
            name={"password"}
            register={register}
            type={"text"}
            errorMessage={errors?.password?.message}
          />
        </div>
        <div style={{ marginBottom: "25px" }}>
          <Input
            label={"Confirm Password*"}
            name={"password_confirmation"}
            register={register}
            type={"text"}
            errorMessage={errors?.password_confirmation?.message}
          />
        </div>
        <div className={style.btnWrapper}>
          <Button
            title={"Update Password"}
            type={"submit"}
            className={style.btnUpdate}
            isLoading={isLoading}
          />
        </div>
      </form>
    </>
  );
};
