import axios from "utils/axios";
import { store, setUser } from "store";
import { UPDATE_PASSWORD_ROUTE, PROFILE_ROUTE } from "utils/endpoints";

export const updateProfileUsername = async ({ data, token, setIsLoading }) => {
  setIsLoading(true);
  const res = await axios.post(PROFILE_ROUTE, data);
  if (res.status === 200) {
    setIsLoading(false);
    store.dispatch(
      setUser({
        user: JSON.stringify(res.data.user),
        token: token,
      })
    );
  } else {
    setIsLoading(false);
  }
};

export const updateProfilePassword = async ({ data, setIsLoading }) => {
  setIsLoading(true);
  const res = await axios.post(UPDATE_PASSWORD_ROUTE, data);
  if (res.status === 201) {
    setIsLoading(false);
  } else {
    setIsLoading(false);
  }
};
