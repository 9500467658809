import {
  getProductList,
  searchProductList,
} from "api-services/product-service";
import { useEffect, useState } from "react";
import { useNavigate, } from "react-router-dom";

export const useViewListAll = () => {
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState("");
  const [isLoadingFilter, setIsLoadingFilter] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [filterList, setFilterList] = useState([]);
  const [productList, setProductList] = useState([]);

  const getSearchInput = (e) => {
    setSearchValue(e.target.value);
    setIsLoadingFilter(true);
  };

  useEffect(() => {
    if (searchValue) {
      const timeOut = setTimeout(() => {
        handleGetList();
      }, 1000);

      return () => {
        clearTimeout(timeOut);
      };
    }
  }, [searchValue]);

  useEffect(() => {
    getProductList({
      setProductList,
      setIsPending: setIsLoading,
    });
  }, []);

  const handleGetList = () => {
    const data = {
      code: searchValue,
    };
    searchProductList({
      data,
      setIsLoading: setIsLoadingFilter,
      setFilterList,
    });
  };

  const handleSearchByCode = (code) => {
    navigate("/search/product-view/" + code);
  };

  const handleSearchResult = () => {
    searchValue !== "" && navigate("/search/search-Result/" + searchValue);
  };

  return {
    isLoading,
    filterList,
    searchValue,
    isLoadingFilter,
    productList,
    handleSearchResult,
    getSearchInput,
    handleSearchByCode,
  };
};
