import { useEffect, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Button from "components/button";

import { useAppSelector } from "store/hooks";

import {
  getBrandList,
  AddBrandName,
  deleteBrandName,
  UpdateBrandName,
} from "api-services/brand-services";

import editIcon from "assets/icons/edit-icon.svg";
import deleteIcon from "assets/icons/delete-icon.svg";
import style from "./brand.module.scss";

export const useBrandName = () => {
  const { brandList } = useAppSelector((state) => state?.app);

  const [deleteId, setDeleteId] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [DeleteLoading, setDeleteLoading] = useState(false);

  const [isPending, setIsPending] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(brandSchema),
  });

  const onSubmit = async (data) => {
    isUpdate
      ? UpdateBrandName({
        data,
        deleteId,
        reset,
        setIsUpdate,
        setIsLoading,
        brandList,
      })
      : AddBrandName({ data, brandList, setIsLoading, reset, });
  };

  const handleDelete = () => {
    deleteBrandName({
      deleteId,
      brandList,
      setOpenDelete,
      setDeleteLoading,
    });
  };

  const handleUpdateBrandName = (row) => {
    setIsUpdate(true);
    setDeleteId(row.id);
    reset(row);
  }

  useEffect(() => {
    brandList.length === 0 && getBrandList({ setIsPending });
  }, []);

  const columns = [
    {
      name: "ID#",
      selector: (row) => row.id,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <Button
            className={style.btnEdit}
            handleClick={() => handleUpdateBrandName(row)}
            icon={editIcon}
          />
          <Button
            className={style.btnDelete}
            handleClick={() => {
              setDeleteId(row.id);
              setOpenDelete(true);
            }}
            icon={deleteIcon}
          />
        </>
      ),
    },
  ];

  return {
    register,
    onSubmit,
    setDeleteId,
    handleSubmit,
    handleDelete,
    setOpenDelete,
    errors,
    columns,
    deleteId,
    isUpdate,
    isLoading,
    brandList,
    isPending,
    openDelete,
    DeleteLoading,
  };
};

export const brandSchema = yup.object().shape({
  name: yup.string().required("Please enter the Brand Name"),
});
