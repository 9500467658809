import React from "react";

import Input from "components/input";
import Modal from "components/modal";
import Button from "components/button";

import style from "./login.module.scss";
import { useLogin } from "./helper";

const Login = ({ openCastModal, setOpenCastModal }) => {
  const { onSubmit, handleClose, handleSubmit, register, errors, loading } =
    useLogin({ setOpenCastModal });

  return (
    <Modal
      open={openCastModal}
      className={style.wrapperClass}
      handleClose={handleClose}
    >
      <div className={style.headingClass}>
        <h2>Login</h2>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"}>
        <div style={{ marginTop: "25px" }}>
          <Input
            label="Email*"
            name={"email"}
            register={register}
            inputClass={style.inputClass}
            errorMessage={errors?.email?.message}
          />
        </div>{" "}
        <div style={{ marginTop: "25px" }}>
          <Input
            label="Password*"
            name={"password"}
            type={"password"}
            register={register}
            inputClass={style.inputClass}
            errorMessage={errors?.password?.message}
          />
        </div>
        <div className={style.footerDiv}>
          <Button
            title={"Login"}
            isLoading={loading}
            type={"submit"}
            className={style.btnLogin}
          />
          <Button
            title={"Cancel"}
            handleClick={handleClose}
            type={"button"}
            className={style.btnCancel}
          />
        </div>
      </form>
    </Modal>
  );
};

export default Login;
