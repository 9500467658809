import React from "react";

import Input from "components/input";
import Button from "components/button";
import Container from "components/container";

import { useSettings } from "./helper";

import style from "./settings.module.scss";

const Settings = () => {
  const { onSubmit, handleSubmit, register, errors, isLoading } = useSettings();

  return (
    <Container className={style.mainWrapper}>
      <h2>Update Settings</h2>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className={style.gridTwoWrapper}>
          <Input
            label={"Reduce Percentage (%)"}
            name={"reduce_price"}
            register={register}
            percentageValue={true}
            errorMessage={errors?.reduce_price?.message}
          />
          <Input
            label={"Reduce Percentage Actual Price (%)"}
            register={register}
            name={"actual_price"}
            percentageValue={true}
            errorMessage={errors?.actual_price?.message}
          />
        </div>
        <div className={style.gridThreeWrapper}>
          <Input
            label={"PT"}
            name={"pt_price"}
            register={register}
            percentageValue={true}
            errorMessage={errors?.pt_price?.message}
          />
          <Input
            label={"PD"}
            name={"pd_price"}
            register={register}
            percentageValue={true}
            errorMessage={errors?.pd_price?.message}
          />
          <Input
            label={"RH"}
            name={"rh_price"}
            register={register}
            percentageValue={true}
            errorMessage={errors?.rh_price?.message}
          />
        </div>
        <Button
          title={"Update"}
          isLoading={isLoading}
          className={style.btnUpdate}
          type={"submit"}
        />
      </form>
      <div className={style.footerWrapper}>
        <div className={style.companyInfo}>
          © 2022 Force Convertor – All Rights Reserved
        </div>
        <div className={style.addressInfo}>
          UAE-Dubai Land Area: Um Ramool Plot Number: 210-0, UAE: 00 971 56 591
          7157 | USA: +1 818 434-3431
        </div>
      </div>
    </Container>
  );
};

export default Settings;
