import axios from "axios";

import { createNotification } from "common/create-notification";
import { store } from "store";

if (process.env.NODE_ENV === "development") {
  axios.defaults.baseURL = "https://www.forceconverter.com:3000/api/v1";
} else {
  axios.defaults.baseURL = "https://www.forceconverter.com:3000/api/v1";
}

axios.interceptors.request.use(
  (req) => {
    const state = store.getState();
    if (state?.app?.token) {
      req.headers.Authorization = state?.app?.token;
    }
    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (res) => {
    if (["post", "put", "delete"].includes(res?.config?.method.toLowerCase())) {
      if (!res.config.url.includes("/products/search")) {
        if (
          res.config.url.includes("authenticate") ||
          res.config.url.includes("products") ||
          res.config.url.includes("brands")
        ) {
          createNotification("success", "Success");
        }
      }

    }
    return res;
  },
  (error) => {
    createNotification("error", error?.response?.statusText);
    if (
      error?.response?.statusText === "Unauthorized" &&
      error?.response?.status === 401
    ) {
      localStorage.setItem("token", "");
      return "Unauthorized";
    }
    return error;
  }
);

export default axios;
