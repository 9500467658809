import { useRef } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import Button from "components/button";

import { store, setToken } from "store";
import { useAppSelector } from "store/hooks";
import { useOutsideClickHook } from "utils/useOutsideClickHook";

import style from "./popup.module.scss";
import { setUser } from "store";

const NavbarPopUp = ({ setPopUp, path }) => {
  const { user } = useAppSelector((state) => state?.app);
  const wrapperRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useOutsideClickHook(wrapperRef, () => {
    setPopUp(false);
  });

  return (
    <>
      <div className={style.mainInner} ref={wrapperRef}>
        {user?.role === "ADMIN" && (
          <div className={style.navMenuWrapper}>
            <Link to={"/products"}>
              <p>Product</p>
            </Link>
            <Link to={"/brands"}>
              <p>Brands</p>
            </Link>
            <Link to={"/settings"}>
              <p>Settings</p>
            </Link>
          </div>
        )}
        <Link to={"/user/profile"}>
          <p>My Profile</p>
        </Link>
        <Button
          title="Logout"
          handleClick={() => {
            store.dispatch(
              setUser({
                user: {},
              })
            );
            store.dispatch(
              setToken({
                token: "",
              })
            );
            navigate("/");
          }}
          className={path ? style.signUpHomeBtn : style.signUp}
        />
      </div>
    </>
  );
};

export default NavbarPopUp;
