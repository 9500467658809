import axios from "utils/axios";
import {
  Add_PRODUCT_ROUTE,
  ATTACHMENT_ROUTE,
  SEARCH_PRODUCT_ROUTE,
  SEARCH_WITH_PRODUCT_ROUTE,
} from "utils/endpoints";

export const AddProduct = async ({
  data,
  setIsLoading,
  setOpen,
  setIsPending,
  setProductList,
}) => {
  setIsLoading(true);
  const res = await axios.post(Add_PRODUCT_ROUTE, data);
  if (res.status === 201) {
    setIsLoading(false);
    setOpen(false);
    getProductList({ setIsPending, setProductList });
  } else {
    setIsLoading(false);
    setOpen(false);
  }
};

export const UpdateProduct = async ({
  data,
  setIsLoading,
  setOpen,
  setIsPending,
  id,
  setProductList,
  setUpdateProductData,
}) => {
  setIsLoading(true);
  const res = await axios.put(Add_PRODUCT_ROUTE + "/" + id, data);
  if (res.status === 201) {
    setIsLoading(false);
    setOpen(false);
    getProductList({ setIsPending, setProductList });
    setUpdateProductData(null);
  } else {
    setIsLoading(false);
    setOpen(false);
  }
};

export const getProductList = async ({ setProductList, setIsPending }) => {
  setIsPending(true);
  const res = await axios.get(Add_PRODUCT_ROUTE);
  if (res.status === 200) {
    setIsPending(false);
    setProductList(res?.data?.products);
  }
  setIsPending(false);
};

export const getFilterData = async ({ data, setProductList, setIsPending }) => {
  const res = await axios.post(SEARCH_WITH_PRODUCT_ROUTE, data);
  if (res.status === 200) {
    setIsPending(false);
    setProductList(res?.data?.products);
  }
  setIsPending(false);
};

export const deleteProduct = async ({
  deleteId,
  setDeleteLoading,
  setOpenDelete,
  setIsPending,
  setProductList,
}) => {
  setDeleteLoading(true);
  const res = await axios.delete(Add_PRODUCT_ROUTE + "/" + deleteId);
  if (res.status === 204) {
    setDeleteLoading(false);
    setOpenDelete(false);
    getProductList({ setProductList, setIsPending });
  } else {
    setDeleteLoading(false);
    setOpenDelete(false);
  }
};

export const searchProductList = async ({
  data,
  setFilterList,
  setIsLoading,
}) => {
  const res = await axios.post(SEARCH_PRODUCT_ROUTE, data);
  if (res.status === 200) {
    setFilterList(res?.data?.products);
    setIsLoading(false);
  }
};

export const externalUpdateProduct = async ({
  data,
  setIsLoading,
  setOpen,
  id,
}) => {
  setIsLoading(true);
  const res = await axios.put(Add_PRODUCT_ROUTE + "/" + id, data);
  if (res.status === 201) {
    setIsLoading(false);
    setOpen(false);
  } else {
    setIsLoading(false);
    setOpen(false);
  }
};

export const showProductById = async ({ setIsLoading, id, setProductData }) => {
  setIsLoading(true);
  const res = await axios.get(Add_PRODUCT_ROUTE + "/" + id);
  if (res.status === 200) {
    setIsLoading(false);
    setProductData(res?.data?.product);
  } else {
    setIsLoading(false);
  }
};

export const deleteProductImg = async (id) => {
  const res = await axios.delete(ATTACHMENT_ROUTE + id + "/purge");
  return res;
};
