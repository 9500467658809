import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import NavbarPopUp from "components/navbar-popup";
import Button from "components/button";
import Login from "pages/auth/login";

import { useAppSelector } from "store/hooks";

import arrow from "assets/dark-arrow.svg";
import avatar from "assets/avatar.svg";
import logo from "assets/logo.png";
import style from "./navbar.module.scss";

const Navbar = () => {
  const { token, user } = useAppSelector((state) => state?.app);
  const { pathname } = useLocation();

  const [isOpen, setIsOpen] = useState(false);
  const [popUp, setPopUp] = useState(false);

  return (
    <>
      <nav className={style.navbar}>
        <div className={style.titleDiv}>
          <Link to={"/"}>
            <img src={logo} alt={"logo"} className={style.logo} />
          </Link>
        </div>
        {token && (
          <>
            <div className={style.flex}>
              {user?.role === "ADMIN" && (
                <div className={style.navMenuWrapper}>
                  <li>
                    <Link to={"/products"}>Product</Link>
                  </li>
                  <li>
                    <Link to={"/brands"}>Brands</Link>
                  </li>
                  <li>
                    <Link to={"/settings"}>Settings</Link>
                  </li>
                </div>
              )}

              <div className={style.loginUserWrapper}>
                <div
                  className={style.navbarLogin}
                  onClick={() => setPopUp(true)}
                >
                  <div className={style.innerDiv}>
                    <img src={avatar} alt="profile img" />
                    <div className={style.infoWrapper}>
                      <h6 style={{ color: "#2c2c2c" }}>{user?.name}</h6>
                      <p style={{ color: "#2c2c2c" }}>
                        {user?.role === "ADMIN" ? "admin" : "user"}
                      </p>
                    </div>
                  </div>
                  <img src={arrow} alt="arrow icon" />
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: popUp ? "85px" : "-300px",
                    transition: "all 0.5s",
                    zIndex: 2600,
                    width: "250px",
                    right: 30,
                  }}
                >
                  <NavbarPopUp
                    setPopUp={setPopUp}
                    path={pathname}
                    popUp={popUp}
                  />
                </div>
              </div>
            </div>
          </>
        )}
        {!token && (
          <div>
            <Button
              title={"Log in"}
              className={style.btnLogin}
              handleClick={() => setIsOpen(true)}
            />
          </div>
        )}
      </nav>

      <Login openCastModal={isOpen} setOpenCastModal={setIsOpen} />
    </>
  );
};

export default Navbar;
