import { memo, useEffect, useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import { useAppSelector } from "store/hooks";
import { getLoginUser } from "api-services/auth";
import { adminRoutes, privateRoutes, publicRoutes } from "./helper";

import Layout from "components/layout";
import Navbar from "components/navbar";
import Loading from "components/loading";

import logo from "assets/logo.png";
import style from "./routes.module.scss";

const Routing = () => {
  const { token, user } = useAppSelector((state) => state?.app);
  const { pathname } = useLocation();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    token !== "" && getLoginUser(setIsLoading);
  }, [token]);

  return (
    <>
      {!token && (
        <Routes>
          {publicRoutes?.map(({ path, element }, index) => (
            <Route
              key={index}
              path={path}
              element={
                <>
                  <Navbar />
                  {element}
                </>
              }
            />
          ))}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      )}
      {isLoading ? (
        <div className={style.loader_wrapper}>
          <h3 style={{ display: "block" }}>
            <img src={logo} alt="app logo" width={220} />
          </h3>
          <Loading className={style.loaderClass} />
        </div>
      ) : (
        <>
          {token && user?.role === "APP_USER" && (
            <Routes>
              {privateRoutes.map(({ path, element }, index) =>
                pathname.includes("/user") ? (
                  <Route
                    key={index}
                    path={path}
                    element={
                      <>
                        <Layout>{element}</Layout>
                      </>
                    }
                  />
                ) : (
                  <Route
                    key={index}
                    path={path}
                    element={
                      <>
                        <Navbar />
                        {element}
                      </>
                    }
                  />
                )
              )}
              <Route path="*" element={<Navigate to="/search" />} />
            </Routes>
          )}
          {token && user?.role === "ADMIN" && (
            <Routes>
              {adminRoutes.map(({ path, element }, index) =>
                path === "/" || path.includes("/search") ? (
                  <Route
                    key={index}
                    path={path}
                    element={
                      <>
                        <Navbar />
                        {element}
                      </>
                    }
                  />
                ) : (
                  <Route
                    key={index}
                    path={path}
                    element={
                      <>
                        <Layout>{element}</Layout>
                      </>
                    }
                  />
                )
              )}
              <Route path="*" element={<Navigate to="/search" />} />
            </Routes>
          )}
        </>
      )}
    </>
  );
};

export default memo(Routing);
