import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { searchProductList } from "api-services/product-service";

export const useSearch = () => {
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [filterList, setFilterList] = useState([]);

  const getSearchInput = (e) => {
    setSearchValue(e.target.value);
    setIsLoading(true);
  };

  useEffect(() => {
    if (searchValue) {
      const timeOut = setTimeout(() => {
        handleGetList();
      }, 1000);

      return () => {
        clearTimeout(timeOut);
      };
    }
  }, [searchValue]);

  const handleGetList = () => {
    const data = {
      code: searchValue,
    };
    searchProductList({ data, setIsLoading, setFilterList });
  };

  const handleSearchResult = () => {
    if (searchValue === "") {
      navigate("/search/getAllResult");
    } else {
      navigate("/search/search-Result/" + searchValue);
    }
  };

  const handleSearchByCode = (code) => {
    navigate("/search/product-view/" + code);
  };

  return {
    isLoading,
    filterList,
    searchValue,
    handleSearchResult,
    handleSearchByCode,
    getSearchInput,
  };
};
