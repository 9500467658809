import React, { useEffect, useState } from "react";
import moment from "moment";
import { Carousel } from "react-responsive-carousel";

import Button from "components/button";

import { deleteProduct, getProductList } from "api-services/product-service";

import logo from "assets/logo.png";
import editIcon from "assets/icons/edit-icon.svg";
import deleteIcon from "assets/icons/delete-icon.svg";
import style from "./product.module.scss";

export const useProduct = () => {
  const [deleteId, setDeleteId] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [updateProductData, setUpdateProductData] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);

  const [openAdd, setOpenAdd] = useState(false);
  const [addLoading, setAddLoading] = useState(false);

  const [isPending, setIsPending] = useState(false);
  const [productList, setProductList] = useState({});

  const handleDelete = () => {
    deleteProduct({
      deleteId,
      setDeleteLoading,
      setOpenDelete,
      setProductList,
      setIsPending,
    });
  };

  useEffect(() => {
    getProductList({ setProductList, setIsPending });
  }, []);

  const columns = [
    {
      name: "ID#",
      selector: (row) => row.id,
      width: "60px", // added line here
    },
    {
      name: "Product Type",
      selector: (row) => row.product_type,
      center: true,
      width: "120px", // added line here
    },
    {
      name: "Code",
      selector: (row) => row.code,
      center: true,
      width: "150px", // added line here
    },
    {
      name: "Brand",
      selector: (row) => row.brand.name,
      center: true,
      width: "100px", // added line here
    },
    {
      name: "PT",
      selector: (row) => parseInt(row.total_pt).toFixed(2),
      center: true,
      width: "100px", // added line here
    },
    {
      name: "PD",
      selector: (row) => parseInt(row.total_pd).toFixed(2),
      center: true,
      width: "100px", // added line here
    },
    {
      name: "RH",
      selector: (row) => parseInt(row.total_rh).toFixed(2),
      center: true,
      width: "100px", // added line here
    },
    {
      name: "Weight (grams)",
      selector: (row) => row.total_weight,
      center: true,
      width: "140px", // added line here
    },
    {
      name: "Image",
      center: true,
      cell: (row) => (
        <>
          <div style={{ padding: "10px 0px" }}>
            {row?.product_image_urls?.length > 0 ? (
              <Carousel
                showStatus={false}
                showIndicators={true}
                dynamicHeight={true}
                swipeable={true}
                emulateTouch={true}
                showThumbs={false}
              >
                {row?.product_image_urls?.map(({ url }) => (
                  <img
                    src={url || logo}
                    width={120}
                    height={100}
                    className={style.tableImgWrapper}
                    alt={"product pic"}
                  />
                ))}
              </Carousel>
            ) : (
              <img
                src={logo}
                width={120}
                height={100}
                className={style.tableImgWrapper}
                alt={"product pic"}
              />
            )}
          </div>
        </>
      ),
      width: "140px", // added line here
    },
    {
      name: "Created At",
      selector: (row) => moment(row.created_at).format("D MMM, YYYY") || "N/A",
      center: true,
      width: "150px", // added line here
    },
    {
      name: "Action",
      width: "150px",
      center: true,

      cell: (row) => (
        <>
          <Button
            className={style.btnEdit}
            icon={editIcon}
            handleClick={() => {
              setOpenAdd(true);
              setUpdateProductData(row);
              setIsUpdate(true);
            }}
          />
          <Button
            className={style.btnDelete}
            handleClick={() => {
              setDeleteId(row.id);
              setOpenDelete(true);
            }}
            icon={deleteIcon}
          />
        </>
      ),
    },
  ];

  return {
    columns,
    openAdd,
    isUpdate,
    isPending,
    openDelete,
    addLoading,
    productList,
    deleteLoading,
    updateProductData,
    setIsUpdate,
    setUpdateProductData,
    setOpenAdd,
    setAddLoading,
    setIsPending,
    setProductList,
    handleDelete,
    setOpenDelete,
    setDeleteId,
  };
};
