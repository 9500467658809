/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import Card from "components/card";

import { useAppSelector } from "store/hooks";
import { adminSidebarList } from "./helper";

import logo from "assets/logo.png";
import style from "./sidebar.module.scss";

const Sidebar = ({ openSidebar, setOpenSidebar }) => {
  const { token, user } = useAppSelector((state) => state?.app);
  const { pathname } = useLocation();

  return (
    <>
      <Card className={style.sidebarCard}>
        <div className={style.sideHeader}>
          <Link to={"/"}>
            <img
              src={logo}
              style={{ width: "220px", height: "80px" }}
              alt="logo img"
            />
          </Link>
        </div>
        <div className={style.listDiv}>
          <ul className={style.ul}>
            <ul className={style.ul}>
              {token &&
                user.role === "ADMIN" &&
                adminSidebarList.map((ele) => (
                  <Link to={ele.path} key={ele.title}>
                    <li className={pathname === ele.path ? style.active : ""}>
                      {ele.title}
                    </li>
                  </Link>
                ))}
            </ul>
          </ul>
        </div>
      </Card>
    </>
  );
};

export default Sidebar;
