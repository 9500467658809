import React, { useState } from "react";

import Container from "components/container";

import style from "./home.module.scss";

const Home = () => {
  return (
    <>
      <div className={style.mainWrapper}>
        <Container className={style.innerWrapper}>
          <h3 className={style.landingTitle}>
            We are specialized in purchasing scrap catalytic converters and
            circuit boards.
          </h3>
        </Container>
      </div>
      <div className={style.footerWrapper}>
        <div className={style.companyInfo}>
          © 2022 Force Convertor – All Rights Reserved
        </div>
        <div className={style.addressInfo}>
          UAE-Dubai Land Area: Um Ramool Plot Number: 210-0, UAE: 00 971 56 591
          7157 | USA: +1 818 434-3431
        </div>
      </div>
    </>
  );
};

export default Home;
