import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: localStorage.getItem("token") || "",
  productList: [],
  brandList: [],
  setting: {},
  user: {},
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setToken(state, { payload: { token } }) {
      localStorage.setItem("token", token);
      state.token = token;
    },
    setUser(state, { payload: { user } }) {
      state.user = user;
    },
    setBrandList(state, { payload: { brandList } }) {
      state.brandList = brandList
    },
    setSetting(state, { payload: { setting } }) {
      state.setting = setting
    },
  },
});
