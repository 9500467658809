import {
  getFilterData,
  getProductList,
  searchProductList,
} from "api-services/product-service";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const useViewList = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingFilter, setIsLoadingFilter] = useState(false);
  const [filterList, setFilterList] = useState([]);
  const [productList, setProductList] = useState([]);

  const getSearchInput = (e) => {
    setSearchValue(e.target.value);
    setIsLoadingFilter(true);
  };

  useEffect(() => {
    if (searchValue) {
      const timeOut = setTimeout(() => {
        handleGetList();
      }, 1000);

      return () => {
        clearTimeout(timeOut);
      };
    }
  }, [searchValue]);

  useEffect(() => {
    getSearchData();
    setSearchValue("");
  }, [id]);

  const handleSearchByCode = (code) => {
    navigate("/search/product-view/" + code);
  };

  const getSearchData = () => {
    const data = {
      code: id,
    };

    getFilterData({
      data: data,
      setIsPending: setIsLoading,
      setProductList: setProductList,
    });
  };

  const handleGetList = () => {
    const data = {
      code: searchValue,
    };
    searchProductList({
      data,
      setIsLoading: setIsLoadingFilter,
      setFilterList,
    });
  };

  const handleSearchResult = () => {
    if (searchValue === "") {
      navigate("/search/getAllResult");
    } else {
      navigate("/search/search-Result/" + searchValue);
    }
  };

  return {
    id,
    isLoading,
    filterList,
    isLoadingFilter,
    searchValue,
    productList,
    getSearchInput,
    handleSearchResult,
    handleSearchByCode,
  };
};
