import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import NavbarPopUp from "components/navbar-popup";

import { useAppSelector } from "store/hooks";

import menu from "assets/icons/navbar-icons/menu.svg";
import arrow from "assets/dark-arrow.svg";
import avatar from "assets/avatar.svg";
import style from "./navbar.module.scss";

const Navbar = ({ setOpenSidebar }) => {
  const { user } = useAppSelector((state) => state?.app);
  const { pathname } = useLocation();

  const [popUp, setPopUp] = useState(false);

  return (
    <nav className={style.navbar}>
      <div className={style.titleDiv}>
        <img
          src={menu}
          alt=""
          className={style.menuIcon}
          onClick={() => setOpenSidebar(true)}
        />
      </div>

      <div className={style.flex}>
        <li>
          <div className={style.profileDiv}>
            <div className={style.loginUserWrapper}>
              <div className={style.navbarLogin} onClick={() => setPopUp(true)}>
                <div className={style.innerDiv}>
                  <img src={avatar} alt="profile img" />
                  <div>
                    <h6 style={{ color: "#2c2c2c" }}>{user?.name}</h6>
                    <p style={{ color: "#2c2c2c" }}>
                      {user?.role === "ADMIN" ? "admin" : "user"}
                    </p>
                  </div>
                </div>
                <img src={arrow} alt="arrow icon" />
              </div>
              <div
                style={{
                  position: "absolute",
                  top: popUp ? "85px" : "-300px",
                  transition: "all 0.5s",
                  zIndex: 2600,
                  width: "250px",
                  right: 30,
                }}
              >
                <NavbarPopUp
                  setPopUp={setPopUp}
                  path={pathname}
                  popUp={popUp}
                />
              </div>
            </div>
          </div>
        </li>
      </div>
    </nav>
  );
};

export default Navbar;
