import React from "react";

import Card from "components/card";
import Input from "components/input";
import Button from "components/button";
import Loading from "components/loading";
import Container from "components/container";

import { useSearch } from "./helper";

import style from "./search.module.scss";

const SearchResult = () => {
  const {
    searchValue,
    isLoading,
    filterList,
    handleSearchResult,
    handleSearchByCode,
    getSearchInput,
  } = useSearch();

  return (
    <>
      <div className={style.mainWrapper}>
        <Container>
          <h3 className={style.landingTitle}>
            True catalyst prices based on Pt Pd Rh quotes
          </h3>
          <div className={style.searchWrapper}>
            <Input
              placeholder={"Search By Product"}
              value={searchValue}
              onChange={getSearchInput}
              className={style.searchInput}
              inputClass={style.innerInput}
            />
            <Button
              title={"Search"}
              handleClick={handleSearchResult}
              className={style.btnSearch}
            />
          </div>
          {searchValue && (
            <Card className={style.cardList}>
              {!isLoading ? (
                filterList.length > 0 ? (
                  filterList?.map((ele, index) => (
                    <div
                      className={style.data}
                      onClick={() => handleSearchByCode(ele[0])}
                      key={index}
                    >
                      {ele[1]}
                    </div>
                  ))
                ) : (
                  <div className={style.data}>No Result Found 😔</div>
                )
              ) : (
                <Loading className={style.loader} />
              )}
            </Card>
          )}
          <p className={style.totalCount}>
            Example:
            <label
              style={{
                textDecoration: "underline",
                cursor: "pointer",
                marginLeft: "5px",
              }}
            >
              1740060
            </label>
          </p>
        </Container>
      </div>
      <div className={style.footerWrapper}>
        <div className={style.companyInfo}>
          © 2022 Force Convertor – All Rights Reserved
        </div>
        <div className={style.addressInfo}>
          UAE-Dubai Land Area: Um Ramool Plot Number: 210-0, UAE: 00 971 56 591
          7157 | USA: +1 818 434-3431
        </div>
      </div>
    </>
  );
};

export default SearchResult;
