import React, { useEffect, useState } from "react";
import DataTable, { defaultThemes } from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";

import Card from "components/card";
import Button from "components/button";
import Loading from "components/loading";
import Container from "components/container";
import AddEditProduct from "./addEditProduct";
import DeleteConfirmModal from "components/confirm-delete-modal";

import { useProduct } from "./helper";
import { useAppSelector } from "store/hooks";

import style from "./product.module.scss";

const Product = () => {
  const {
    deleteLoading,
    productList,
    openDelete,
    columns,
    openAdd,
    isUpdate,
    updateProductData,
    isPending,
    setUpdateProductData,
    setOpenAdd,
    handleDelete,
    setOpenDelete,
    setDeleteId,
    setIsPending,
    setProductList,
    setIsUpdate,
  } = useProduct();

  const { token, user } = useAppSelector((state) => state?.app);

  const tableData = {
    columns,
    data: productList?.records,
  };

  const ExpandedComponent = ({ data }) => (
    <>
      <div className={style.row}>
        {data?.sub_products &&
          data?.sub_products?.map(({ pd, pt, rh, weight }, index) => (
            <Card className={style.cardDetails} key={index}>
              <div className={style.header}>Product {index + 1}</div>
              <div className={style.bodyWrapper}>
                <table>
                  <tr>
                    <td>PT</td>
                    <td>{pt}</td>
                  </tr>
                  <tr>
                    <td>PD</td>
                    <td>{pd}</td>
                  </tr>
                  <tr>
                    <td>RH</td>
                    <td>{rh}</td>
                  </tr>
                  <tr>
                    <td>Weight (grams)</td>
                    <td>{weight}</td>
                  </tr>
                </table>
              </div>
            </Card>
          ))}
      </div>
    </>
  );

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  return (
    <>
      <Container className={style.mainWrapper}>
        <h2>Products</h2>
        <Button
          title={"Add Product"}
          handleClick={() => {
            setIsUpdate(false);
            setUpdateProductData();
            setOpenAdd(true);
          }}
          className={style.btnUpdate}
        />
        <div className={style.tableWrapper}>
          <DataTableExtensions {...tableData}>
            <DataTable
              pagination
              expandableRows
              columns={columns}
              fixedHeader={true}
              progressPending={isPending}
              customStyles={customStyles}
              data={productList && productList?.records}
              expandableRowsComponent={ExpandedComponent}
              paginationRowsPerPageOptions={[5, 10, 15, 20, 100]}
              progressComponent={<Loading className={style.loadingTable} />}
            />
          </DataTableExtensions>
        </div>
      </Container>

      <div className={style.footerWrapper}>
        <div className={style.companyInfo}>
          © 2022 Force Convertor – All Rights Reserved
        </div>
        <div className={style.addressInfo}>
          UAE-Dubai Land Area: Um Ramool Plot Number: 210-0, UAE: 00 971 56 591
          7157 | USA: +1 818 434-3431
        </div>
      </div>
      {openDelete && (
        <DeleteConfirmModal
          deleteModalOpen={openDelete}
          deleteLoading={deleteLoading}
          setDeleteId={setDeleteId}
          handleDeleteRow={handleDelete}
          setDeleteModalOpen={setOpenDelete}
        />
      )}

      {openAdd && (
        <AddEditProduct
          openModal={openAdd}
          setOpenModal={setOpenAdd}
          setIsPending={setIsPending}
          setProductList={setProductList}
          isUpdate={isUpdate}
          setUpdateProductData={setUpdateProductData}
          setIsUpdate={setIsUpdate}
          updateProductData={updateProductData}
        />
      )}
    </>
  );
};

export default Product;
