import { useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { login } from "api-services/auth";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";

export const useLogin = ({ setOpenCastModal }) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(loginSchema),
  });

  const handleClose = () => {
    setOpenCastModal(false);
    reset();
  };

  const onSubmit = async (data) => {
    login({ data, setOpenCastModal, setLoading, navigate });
  };

  return {
    register,
    onSubmit,
    setLoading,
    handleClose,
    handleSubmit,
    errors,
    loading,
  };
};

export const loginSchema = yup.object().shape({
  email: yup.string().required("email is required").email(),
  password: yup.string().required("Password is required"),
});
