import React from "react";

import Routing from "./routes/index";
const App = () => {
  return (
    <>
      <Routing />
    </>
  );
};

export default App;
