import axios from "utils/axios";
import { store, setUser, setToken } from "store";
import { LOGIN_INFO_ROUTE, LOGIN_ROUTE } from "utils/endpoints";

export const login = async ({
  data,
  navigate,
  setLoading,
  setOpenCastModal,
}) => {
  setLoading(true);

  const res = await axios.post(LOGIN_ROUTE, data);
  if (res.status === 200) {
    store.dispatch(
      setToken({
        token: res?.data?.auth_token,
      })
    );
    store.dispatch(
      setUser({
        user: res?.data?.user,
      })
    );
    setOpenCastModal(false);
    setLoading(false);
    navigate("/search");
  }
  setLoading(false);
};

export const getLoginUser = async (setIsLoading) => {
  setIsLoading(true);
  const res = await axios.post(LOGIN_INFO_ROUTE);
  if (res.status === 200) {
    store.dispatch(
      setUser({
        user: res?.data?.user,
      })
    );
    setIsLoading(false);
  }
  res === "Unauthorized" &&
    store.dispatch(
      setToken({
        token: "",
      })
    );
};
