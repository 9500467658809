import SearchResultNoId from "pages/product-veiw-list-withoutID";
import SearchResult from "pages/product-veiw-list";
import ProductView from "pages/product-view-page";
import BrandName from "pages/brand-name";
import Settings from "pages/settings";
import Product from "pages/product";
import Profile from "pages/profile";
import Search from "pages/search";
import Home from "pages/home";

export const publicRoutes = [
  {
    path: "/",
    element: <Home />,
  },
];

export const privateRoutes = [
  {
    path: "/search",
    element: <Search />,
  },
  {
    path: "/search/search-Result/:id",
    element: <SearchResult />,
  },
  {
    path: "/search/getAllResult",
    element: <SearchResultNoId />,
  },
  {
    path: "/user/profile",
    element: <Profile />,
  },
  {
    path: "/search/product-view/:id",
    element: <ProductView />,
  },
];

export const adminRoutes = [
  {
    path: "/search",
    element: <Search />,
  },
  {
    path: "/search/search-Result/:id",
    element: <SearchResult />,
  },
  {
    path: "/search/getAllResult",
    element: <SearchResultNoId />,
  },
  {
    path: "/products",
    element: <Product />,
  },
  {
    path: "/brands",
    element: <BrandName />,
  },
  {
    path: "/settings",
    element: <Settings />,
  },
  {
    path: "/user/profile",
    element: <Profile />,
  },
  {
    path: "/search/product-view/:id",
    element: <ProductView />,
  },
];
