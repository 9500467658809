import { useEffect, useState } from "react";
import * as yup from "yup";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFieldArray, useForm } from "react-hook-form";

import { useAppSelector } from "store/hooks";
import { getBrandList } from "api-services/brand-services";
import { deleteProductImg, getProductList } from "api-services/product-service";
import Button from "components/button";
import { BRAND_NAME_ROUTE } from "utils/endpoints";

export const useAddProduct = ({
  setOpenModal,
  setProductList,
  setIsPending,
  isUpdate,
  setIsUpdate,
  setUpdateProductData,
  updateProductData,
}) => {
  const { token } = useAppSelector((state) => state?.app);
  const [isLoading, setIsLoading] = useState(false);
  const [brandList, setBrandList] = useState({});
  const [showImage, setImage] = useState(null);
  const [multipleImages, setMultipleImages] = useState([]);
  const [multipleImages1, setMultipleImages1] = useState([]);
  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(productAddSchema),
    defaultValues: {
      sub_products_attributes: [{ weight: "", pd: "", pt: "", rh: "" }],
    },
  });

  const file = watch("product_image");

  const { fields, append, remove } = useFieldArray({
    control,
    name: "sub_products_attributes",
  });

  const handlAddSubProduct = () => {
    append({ weight: "", pd: "", pt: "", rh: "" });
  };

  const toFormData = ((f) => f(f))((h) => (f) => f((x) => h(h)(f)(x)))(
    (f) => (fd) => (pk) => (d) => {
      if (d instanceof Object) {
        Object.keys(d).forEach((k) => {
          const v = d[k];
          if (pk) k = `${pk}[${k}]`;
          if (
            v instanceof Object &&
            !(v instanceof Date) &&
            !(v instanceof File)
          ) {
            return f(fd)(k)(v);
          } else {
            fd.append(k, v);
          }
        });
      }
      return fd;
    }
  )(new FormData())(); // this function get the list of brand

  useEffect(() => {
    async function BrandList() {
      const res = await axios.get(BRAND_NAME_ROUTE);
      setBrandList(res?.data?.users?.records);
    }

    BrandList();
  }, []); // this function for preview the img

  useEffect(() => {
    file && previewImg();
  }, [file]);

  useEffect(() => {
    if (isUpdate) {
      reset({
        code: updateProductData?.code,
        product_type: updateProductData?.product_type,
        brand_id: updateProductData.brand.id,
        sub_products_attributes: updateProductData?.sub_products,
      });
      setMultipleImages(updateProductData?.product_image_urls);
    }
  }, [brandList]);

  const previewImg = () => {
    if (file[0]) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImage(reader.result);
      });
      reader.readAsDataURL(file[0]);
    }
  }; // submit the form

  const submit = (data) => {
    if (multipleImages1.length > 0) {
      isUpdate ? handleUpdateProduct(data) : handleAddProduct(data);
    } else {
      isUpdate ? handleUpdateProduct(data) : handleAddProduct(data);
    }
  };

  const handleAddProduct = (data) => {
    const url = `https://www.forceconverter.com:3000/api/v1/products/`;
    const config = {
      "content-type": "multipart/form-data",
      Authorization: token,
    };
    setIsLoading(true);
    const list = toFormData(data);
    data.product_images = multipleImages1;
    data?.product_images?.forEach((d) => {
      list.append("product_images[]", d[0]);
    });
    axios
      .post(url, list, config)
      .then((res) => {
        setIsLoading(false);
        setOpenModal(false);
        toFormData({});
        getProductList({ setProductList, setIsPending }); // window.location.reload();
      })
      .catch((e) => {
        setIsLoading(false);
        toFormData({});
        console.error(e);
      });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setUpdateProductData({});
    reset({
      code: "",
      brand_id: "",
      product_type: "",
      sub_products_attributes: [{ weight: "", pd: "", pt: "", rh: "" }],
    });
  };

  const handleUpdateProduct = (data) => {
    data.sub_products_attributes.forEach((object) => {
      delete object["created_at"];
      delete object["updated_at"];
      delete object["reduce_price"];
      delete object["actual_price"];
    });
    const url = `https://www.forceconverter.com:3000/api/v1/products/${updateProductData.id}`;
    const config = {
      "content-type": "multipart/form-data",
      Authorization: token,
    };
    setIsLoading(true);

    const list = toFormData(data);
    data.product_images = multipleImages1;
    data?.product_images?.forEach((d) => {
      list.append("product_images[]", d[0]);
    });
    axios
      .put(url, list, config)
      .then((res) => {
        setIsLoading(false);
        setOpenModal(false);
        toFormData({});
        getProductList({ setProductList, setIsPending });
      })
      .catch((e) => {
        setIsLoading(false);
        toFormData({});
        setUpdateProductData({});
        console.error(e);
      });
  };

  const changeMultipleFiles = (e) => {
    if (e.target.files) {
      setMultipleImages1((prevImages) => [...prevImages, e.target.files]);
      const imageArray = Array.from(e.target.files).map((file) =>
        URL.createObjectURL(file)
      );
      setMultipleImages((prevImages) =>
        prevImages.concat({ id: imageArray[0], url: imageArray })
      );
    }
  };

  const handleDeleteProductImg = async (id) => {
    if (typeof id === "string") {
      setMultipleImages((multipleImages) =>
        multipleImages?.filter((img) => img?.id !== id)
      );
    } else {
      const res = await deleteProductImg(id);
      res.status === 200 &&
        setMultipleImages((multipleImages) =>
          multipleImages?.filter((img) => img?.id != id)
        );
    }
  };

  const renderImg = (data, style) => {
    return data?.map(({ url, id }, index) => (
      <div className={style.imgWrapper}>
        <Button
          title={"X"}
          handleClick={() => handleDeleteProductImg(id)}
          type={"button"}
          className={style.btnRemoveImg}
        />
        <img className="image" src={url} alt="" key={index} />
      </div>
    ));
  };

  return {
    handlAddSubProduct,
    handleCloseModal,
    handleSubmit,
    register,
    submit,
    remove,
    watch,
    renderImg,
    setMultipleImages,
    changeMultipleFiles,
    errors,
    fields,
    multipleImages1,
    showImage,
    isLoading,
    brandList,
    multipleImages,
  };
};

export const productAddSchema = yup.object().shape({
  product_type: yup.string().required("Please select product type"),
  code: yup.string().required("Please enter the product code"),
  brand_id: yup.string().required("Please select brand name"),
  sub_products_attributes: yup.array().of(
    yup.object().shape({
      weight: yup.string().required("weight is required"),
      pd: yup.string().required("PD is required"),
      pt: yup.string().required("PT is required"),
      rh: yup.string().required("RH is required"),
    })
  ),
});
