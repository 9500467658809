import {
  deleteProduct,
  externalUpdateProduct,
  getFilterData,
  searchProductList,
  showProductById,
} from "api-services/product-service";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const useProductDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [deleteId, setDeleteId] = useState("");

  const [openUpdate, setOpenUpdate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingFilter, setIsLoadingFilter] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [filterList, setFilterList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [productData, setProductData] = useState({});

  const getSearchInput = (e) => {
    setSearchValue(e.target.value);
    setIsLoadingFilter(true);
  };

  useEffect(() => {
    if (searchValue) {
      const timeOut = setTimeout(() => {
        handleGetList();
      }, 1000);

      return () => {
        clearTimeout(timeOut);
      };
    }
  }, [searchValue]);

  useEffect(() => {
    !openUpdate && getSearchData();
  }, [id, openUpdate]);

  const getSearchData = async () => {
    await showProductById({
      id,
      setIsLoading,
      setProductData,
    });
  };

  const handleGetList = () => {
    const data = {
      code: searchValue,
    };
    searchProductList({
      data,
      setIsLoading: setIsLoadingFilter,
      setFilterList,
    });
  };

  const handleNavigateToPanel = () => {
    navigate("/products");
  };

  const handleSearchResult = () => {
    if (searchValue === "") {
      navigate("/search/getAllResult");
    } else {
      navigate("/search/search-Result/" + searchValue);
    }
  };

  const handleSearchByCode = (code) => {
    setSearchValue("");
    navigate("/search/product-view/" + code);
  };

  const handleDeleteProdut = (id) => {
    setOpenDelete(true);
    setDeleteId(id);
  };

  const handleDelete = () => {
    deleteProduct({
      deleteId,
      setDeleteLoading,
      setOpenDelete,
      setProductList,
      setIsPending,
    });
    navigate("/");
  };

  const handleUpdate = () => {
    setOpenUpdate(true);
  };

  return {
    id,
    isLoading,
    isPending,
    openDelete,
    filterList,
    productList,
    searchValue,
    deleteLoading,
    isLoadingFilter,
    productData,
    openUpdate,
    setOpenUpdate,
    handleUpdate,
    setProductList,
    setIsPending,
    setOpenDelete,
    setDeleteId,
    handleDeleteProdut,
    handleDelete,
    getSearchInput,
    handleSearchByCode,
    handleSearchResult,
    handleNavigateToPanel,
  };
};
