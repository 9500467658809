import React from "react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import { ToastContainer } from "react-toastify";
import { BrowserRouter as Router } from "react-router-dom";

import { store } from "./store";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "./index.scss";
import "react-toastify/dist/ReactToastify.css";
import "react-data-table-component-extensions/dist/index.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <Router>
      <Provider store={store}>
        <App />
      </Provider>
      <ToastContainer />
    </Router>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
