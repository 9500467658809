export const adminSidebarList = [
  {
    title: "Products",
    active: "products",
    path: "/products",
  },
  {
    title: "Brands",
    active: "brands",
    path: "/brands",
  },
  {
    title: "Settings",
    active: "settings",
    path: "/settings",
  },
];

export const userSidebarList = [
  {
    title: "Settings",
    active: "settings",
    path: "/settings",
  },
];
