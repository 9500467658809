import React from "react";

import Input from "components/input";
import Modal from "components/modal";
import Button from "components/button";
import Select from "components/select";

import { useAddProduct } from "./helper";

import style from "./addEdit.module.scss";

const AddEditProduct = ({
  setProductList,
  setOpenModal,
  setIsPending,
  openModal,
  isUpdate,
  setIsUpdate,
  updateProductData,
  setUpdateProductData,
}) => {
  const {
    handlAddSubProduct,
    handleSubmit,
    register,
    submit,
    remove,
    renderImg,
    handleCloseModal,
    changeMultipleFiles,
    multipleImages,
    brandList,
    errors,
    fields,
    isLoading,
  } = useAddProduct({
    setOpenModal,
    setIsPending,
    setProductList,
    isUpdate,
    setIsUpdate,
    updateProductData,
    setUpdateProductData,
  });
  console.log(brandList);
  return (
    <Modal
      open={openModal}
      className={style.wrapperClass}
      handleClose={() => {
        setOpenModal(false);
      }}
    >
      <div className={style.headingClass}>
        <h2>{isUpdate ? "Update Product" : "Add Product"} </h2>
      </div>
      <form onSubmit={handleSubmit(submit)} autoComplete={"off"}>
        <div style={{ marginTop: "15px" }}>
          <Select
            label="Product Type *"
            name={"product_type"}
            register={register}
            errorMessage={errors?.product_type?.message}
          >
            <option value="">select</option>
            <option value="Ceramic">Ceramic</option>
            <option value="Metal">Metal</option>
          </Select>
        </div>
        <div style={{ marginTop: "15px" }}>
          <Input
            register={register}
            name={"code"}
            label={"Product Code *"}
            errorMessage={errors?.code?.message}
          />
        </div>
        <div style={{ marginTop: "15px" }}>
          <Select
            label="Brand *"
            name={"brand_id"}
            register={register}
            errorMessage={errors?.brand_id?.message}
          >
            <option value="">select</option>
            {brandList?.length > 0 ? (
              brandList?.map(({ name, id }) => (
                <option value={id} key={id}>
                  {name}
                </option>
              ))
            ) : (
              <option value="">NO DATA FOUND</option>
            )}
          </Select>
        </div>
        <div
          style={{ marginTop: "15px", display: "flex", justifyContent: "end" }}
        >
          <Button
            title={"Add Sub-Product"}
            type={"button"}
            disabled={fields.length >= 3 && true}
            handleClick={handlAddSubProduct}
            className={
              fields.length >= 3 ? style.disableSubProduct : style.addSubProduct
            }
          />
        </div>
        {fields &&
          fields?.map((ele, index) => {
            return (
              <div
                key={index}
                style={{ position: "relative", padding: "10px 0px" }}
              >
                {index !== 0 && (
                  <Button
                    title={"X"}
                    type={"button"}
                    handleClick={() => remove(index)}
                    className={style.btnRemoveField}
                  />
                )}
                <div className={style.gridFour}>
                  <Input
                    label="PT*"
                    name={`sub_products_attributes.${index}.pt`}
                    register={register}
                    percentageValue={true}
                    inputClass={style.inputClass}
                    errorMessage={
                      errors?.sub_products_attributes &&
                      errors?.sub_products_attributes[index]?.pt?.message
                    }
                  />
                  <Input
                    label="PD*"
                    name={`sub_products_attributes.${index}.pd`}
                    register={register}
                    percentageValue={true}
                    inputClass={style.inputClass}
                    errorMessage={
                      errors?.sub_products_attributes &&
                      errors?.sub_products_attributes[index]?.pd?.message
                    }
                  />
                  <Input
                    label="RH*"
                    name={`sub_products_attributes.${index}.rh`}
                    register={register}
                    percentageValue={true}
                    inputClass={style.inputClass}
                    errorMessage={
                      errors?.sub_products_attributes &&
                      errors?.sub_products_attributes[index]?.rh?.message
                    }
                  />
                  <Input
                    label="Weight"
                    name={`sub_products_attributes.${index}.weight`}
                    register={register}
                    percentageValue={true}
                    inputClass={style.inputClass}
                    errorMessage={
                      errors?.sub_products_attributes &&
                      errors?.sub_products_attributes[index]?.weight?.message
                    }
                  />
                </div>
              </div>
            );
          })}
        <div style={{ marginTop: "15px" }}>
          <input onChange={changeMultipleFiles} type={"file"} />
        </div>
        {renderImg(multipleImages, style)}
        <div className={style.footerDiv}>
          <Button
            title={isUpdate ? "Update Product" : "Add Product"}
            isLoading={isLoading}
            type={"Submit"}
            className={style.btnLogin}
          />
          <Button
            title={"Cancel"}
            handleClick={() => {
              handleCloseModal();
            }}
            type={"button"}
            className={style.btnCancel}
          />
        </div>
      </form>
    </Modal>
  );
};

export default AddEditProduct;
